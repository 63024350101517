<template>
  <div class="page">
    <a-page-header title="设置角色权限" @back="back" />
    <a-list itemLayout="horizontal" :bordered="true">
      <a-list-item v-for="item in roleList" :key="item.roleId">
        <div class="name-box">
          {{ item.roleName }}<a-divider type="vertical" />
        </div>
        <a-checkbox-group
          v-model="item.permissionIds"
          @change="onChangePermission($event, item.roleId)"
        >
          <a-checkbox
            v-for="option in permissionList"
            :key="option.permissionId"
            :value="option.permissionId"
            >{{ option.permissionName }}</a-checkbox
          >
        </a-checkbox-group>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import { roleFilter, roleUpdate } from "@/apis/role";
import { permissionFilter } from "@/apis/permission";
import { message } from "ant-design-vue";

export default {
  data() {
    return {
      roleList: [],
      permissionList: [],
    };
  },
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      permissionFilter({ numOfPage: "1", rowsInPage: "20" }).then((res) => {
        const {
          code,
          data: { resList },
        } = res?.data;
        if (code === "200") {
          this.permissionList = resList;
          roleFilter({ numOfPage: "1", rowsInPage: "20" }).then((res) => {
            const {
              code,
              data: { resList },
            } = res?.data;
            if (code === "200") {
              this.roleList = resList.filter(
                (r) =>
                  (r.permissionIds = this.formatePermissionIds(r.permissionIds))
              );
            }
          });
        }
      });
    },
    formatePermissionIds(ids) {
      if (ids === "" || this.permissionList.length < 1) return [];
      return ids.split(",");
    },
    back() {
      this.$router.push("/system/data/role");
    },
    onChangePermission(val, roleId) {
      roleUpdate({
        roleId,
        roleName: "none",
        permissionIds: val.join(","),
      }).then((res) => {
        const { code } = res?.data;
        if (code === "200") {
          message.info("修改成功");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background-color: #fff;
  padding: 0 20px 20px 20px;
  .ant-list-item {
    // height: 40px;
    line-height: 40px;
    padding: 0 24px;
    display: flex;
    .name-box {
      width: 100px;
      height: 40px;
      .ant-divider {
        width: 1px;
        height: 40px;
        background-color: #f0f0f0;
        float: right;
      }
    }
    .ant-checkbox-group {
      margin-left: 20px;
      flex: 1 1 0%;
    }
  }
}
</style>
