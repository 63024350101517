import request from "@/utils/request";

function roleFilter(data) {
  return request({
    url: "/show/role/filter.diti",
    method: "post",
    data,
  });
}

function roleAdd(data) {
  return request({
    url: "/show/role/add.diti",
    method: "post",
    data,
  });
}

function roleUpdate(data) {
  return request({
    url: "/show/role/update.diti",
    method: "post",
    data,
  });
}

function roleDel(data) {
  return request({
    url: "/show/role/del.diti",
    method: "post",
    data,
  });
}

export { roleFilter, roleAdd, roleUpdate, roleDel };
